<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.FullName.charAt(0).toUpperCase() + userData.FullName.slice(1) }}
        </p>
        <span class="user-status">{{ userData.UserRoleName }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.FullName.charAt(0).toUpperCase() + userData.FullName.slice(1)"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ path: '/apps/change-password' }"
    >
      <span>Change Password</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      userData: getUserData(),
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove everythings from localStorage
      localStorage.removeItem('userToken')
      localStorage.removeItem('userData')
      this.$cookies.remove('KodeUnitKerja')
      this.$cookies.remove('UserRole')
      VueCookies.remove('KodeUnitKerja')
      VueCookies.remove('UserRole')
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
